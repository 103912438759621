<template>
    <div :class="loginClass" :style="{background: 'url('+cssVars.backGroundPng+')','background-size': '100% 100%'}">
        <el-form :model="loginForm" :rules="loginFormRules" ref="loginFormRef" class="loginForm">
            <el-form-item>
                <h2 class="title">{{loginMsg}}</h2>
            </el-form-item>

            <el-form-item prop="usercode">
                <el-input ref='usercodeRef' type="text" placeholder="请输入用户名" @input="e => loginForm.usercode = validForbid(e)" v-model="loginForm.usercode" suffix-icon="el-icon-s-custom" autocomplete="off" clearable autofocus="true" @keyup.enter.native="doNext('usercode')"/>
            </el-form-item>

            <el-form-item prop="password">
                <el-input ref='passwordRef' type="password" placeholder="请输入密码" @input="e => loginForm.password = validForbid(e)" v-model="loginForm.password" suffix-icon="el-icon-view" autocomplete="off" clearable @keyup.enter.native="doNext('password')"/>
            </el-form-item>

            <el-form-item prop="verifyCode"  v-if="useYzm">
                <div style="display: flex;flex-direction: row;justify-content: space-between;" v-if="yzmType=='1'">
                    <div style="flex: 3">
                        <el-input ref='verifyCodeRef' type="text" placeholder="请输入右侧验证码" @input="e => loginForm.verifyCode = validForbid(e)" v-model="loginForm.verifyCode" autocomplete="off" clearable @keyup.enter.native="doNext('verifyCode')"/>
                    </div>
                    <div style="flex: 1;margin-left: 5px;">
                        <VerfiCode ref="verifyCodeComRef"></VerfiCode>
                    </div>
                </div>
                <Silder status="silderStatus" ref="sliderRef" :successFun='sliderSuccess' :errorFun='sliderError' v-if="yzmType=='0'"/>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" v-loading.fullscreen.lock="fullscreenLoading" @click="submitForm()" class="loginBtn">登录</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    const config = require('./utils/config.js');
    //数字验证码
    import VerfiCode from "@/components/utils/VerfiCode";
    //滑块验证码
    import Silder from "@/components/utils/Silder";
    import { ref,computed,getCurrentInstance,reactive,toRefs,onMounted,defineComponent} from 'vue';
    export default defineComponent({
        name: "Login",
        setup(props,context){
            const {proxy}=getCurrentInstance();
            const utils=proxy.utils;

            const loginFormRef=ref(null);
            const usercodeRef=ref(null);
            const passwordRef=ref(null);
            const verifyCodeRef=ref(null);
            const verifyCodeComRef=ref(null);
            const sliderRef=ref(null);
            let store = proxy.$store;


            let dataObj=reactive({
                silderStatus: false,//滑块状态（滑块是否验证成功）
                yzmType:'0',//验证码种类。0：滑块验证 1：数字验证
                backgroundImage:'',//登录页背景图片（onMounted里面会改变）
                useYzm: true,//登录是否启用验证码
                loginForm: {
                    usercode: "",
                    password: "",
                    clientType:0,//客户端类别，0：pc 1：手机
                    verifyCode: ""
                },
                loginFormRules: {
                    usercode: [{required: true, message: "请输入用户名", trigger: "blur"}],
                    password: [{required: true, message: "请输入密码", trigger: "blur"}]
                },
                fullscreenLoading:false,//点击登录按钮，为页面增加遮罩层，该变量控制的是遮罩层的显示/影藏状态
                loginMsg:config.title,//登录页面欢迎标语
                cssVars:{
                    backGroundPng: config.title=='老公货嘀运营管理平台'?require('./assets/picResources/business.png'):require('./assets/picResources/sass.png')
                }
            })

            onMounted(async ()=>{
                sessionStorage.clear();//清空sessionStore
                store.dispatch("closeAllVisitedTabsView");//关闭掉所有的tabs
                usercodeRef.value.focus();//光标定位到第一个文本框
            })
            //系统建议用谷歌浏览器，这里检查是否为谷歌浏览器，给予相应提示
            const checkBrows=()=>{
                let userAgent=navigator.userAgent;
                if(userAgent.indexOf("Chrome")==-1){
                    proxy.$notify({
                        title: '提示',
                        type: 'warning',
                        dangerouslyUseHTMLString: true,
                        position: 'top-left',
                        message: '为了能够正常使用系统，请使用<span style="color: red;">谷歌</span>浏览器',
                        duration: 0
                    });
                }
            }
            checkBrows();
            //滑块拖动成功事件
            const sliderSuccess=()=>{
                dataObj.silderStatus=true;
            }
            //滑块拖动失败事件
            const sliderError=()=>{}
            //登录验证处理
            const doLogin=async()=> {
                //数字验证码是否正确
                if(dataObj.useYzm && dataObj.yzmType=='1'){
                    let isVerfiCodeRight=verifyCodeComRef.value.verfiCode(dataObj.loginForm.verifyCode);
                    if(!isVerfiCodeRight){
                        dataObj.fullscreenLoading=false;
                        utils.$$tools.info({message: "验证码不正确"});
                        return ;
                    }
                }else if(dataObj.useYzm && dataObj.yzmType=='0' && !dataObj.silderStatus){//滑块验证没有通过
                    dataObj.fullscreenLoading=false;
                    utils.$$tools.info({message: "请拖动滑块到最右边"});
                    return;
                }
                dataObj.loginForm.password = utils.$$str.encrypt(dataObj.loginForm.password);//把输入密码加密处理
                try {
                    let res = await utils.$$api.login(dataObj.loginForm);
                    if(res[utils.$$const.baseVar.errorCode]==utils.$$const.sysCode._0000){//后台处理成功返回
                        //注意：放入vuex中的东西在刷新的时候会丢掉，所以在路由全局拦截那里需要再次设置；
                        //     放到sessionStorage里面的东西刷新的时候不会丢掉，关闭浏览器之后会丢掉
                        //     放入localStorage里面的东西，不管刷新还是关闭浏览器都不会丢掉
                        store.dispatch("configEncryptData", res.encryptData);//设置是否加密传输数据到vuex
                        store.dispatch("configChangePersonalInfoPermission", res.hasChangePersonalInfosPermission);//设置是否具有修改个人信息的权限数据到vuex

                        sessionStorage.setItem("userMenus", utils.$$str.encrypt(res.userMenus)); //设置菜单字符串，方便在slidBar那里取该用户的菜单
                        sessionStorage.setItem("welcomeMsg", res.welcomeMsg);//设置welcomeMsg

                        dataObj.loginForm.password = '';//清空密码输入框
                        dataObj.loginForm.hasEditPortal=res.hasEditPortal;//是否具有门户
                        dataObj.loginForm.userName=res.userName;//res.userName为帐号的昵称，把它赋值给loginForm.userName，最后会显示在top组件的个人信息那里

                        sessionStorage.setItem("userInfo",utils.$$str.encrypt(JSON.stringify(dataObj.loginForm)));//存储userInfo
                        sessionStorage.setItem(utils.$$const.baseVar.jfAccessToken, res[utils.$$const.baseVar.jfAccessToken]);//存储token

                        sessionStorage.setItem("aloneServerInfo",utils.$$str.encrypt(res.aloneServerInfo));
                        sessionStorage.setItem('mainMsg',res.mainMsg);//主页动画的欢迎语


                        dataObj.fullscreenLoading=false;
                        proxy.$router.push('/');
                        utils.$$tools.success({message: "登录成功"});
                    }else{//后台验证失败
                        dataObj.loginForm.password = "";//清除密码输入框
                        utils.$$tools.info({message: res.msg});
                        if(dataObj.useYzm && dataObj.yzmType=='1'){//如果要用验证码而且采用的是数字验证码，那么需要刷新数字
                            verifyCodeComRef.value.createCode();
                            dataObj.loginForm.verifyCode = "";
                        }else if(dataObj.useYzm && dataObj.yzmType=='0'){//如果要用验证码而且采用的是滑块验证码，那么需要重置滑块
                            dataObj.silderStatus=false;
                            sliderRef.value.resetSlider();
                        }
                    }
                    if(dataObj.fullscreenLoading==true)dataObj.fullscreenLoading=false;//关闭页面遮罩层
                } catch (err) {
                    dataObj.fullscreenLoading=false;
                    usercodeRef.value.focus();
                }
            }


            //登录按钮事件
            const submitForm=()=>{
                loginFormRef.value.validate(valid => {
                    if (valid) {
                        dataObj.fullscreenLoading=true;
                        doLogin();
                    } else {
                        proxy.$message.info("请填写完登录信息");
                        return false;
                    }
                });
            }
            //页面控件回车处理事件（跳转到下一个操作项，相当于tab）
            const doNext=(field)=> {
                switch (field) {
                    case "usercode"://如果当前是usercode发出的回车事件，则把光标定位到password输入框
                        passwordRef.value.focus();
                        break;
                    case "password"://如果当前是password发出的回车事件，则看是否需要验证码，需要验证码且为数字验证码，那么光标定位到数字输入框，否则提交登录表单
                        if (dataObj.useYzm && dataObj.yzmType=='1') {
                            verifyCodeRef.value.focus();
                        } else {
                            submitForm();
                        }
                        break;
                    case "verifyCode"://如果当前是verifyCode发出的回车事件，则提交登录表单
                        submitForm();
                        break;
                }
            }
            //登录页面的动态样式
            const loginClass=computed(()=>{
                return {
                    login: true//是否启用login基本css
                };
            })
            return { //必须返回 模板中才能使用
                ...toRefs(dataObj),loginFormRef,usercodeRef,passwordRef,verifyCodeRef,sliderRef,verifyCodeComRef,submitForm,doNext,doLogin,sliderSuccess,sliderError,loginClass
            }
        },
        components: {VerfiCode,Silder}
    });
</script>

<style scoped>
    /*登录动画1 */
    .login {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /*background: url("./assets/picResources/business.png");*/
        /*background-size: 100% 100%;*/
    }

    .loginForm {
        opacity: 1;
        width: 350px;
        padding: 40px 40px 40px 40px;
        border-radius: 10px;
        border: 1px solid #eaeaea;
        /*box-shadow: 0 0 25px #cac6c6;*/
        /*表单默认是透明的，让其变为不透明*/
        background-color: rgba(255, 255, 255,1);
    }

    .loginBtn {
        width: 100%;
    }

    .title {
        width: 100%;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        /*color: darkcyan;*/
        /*border-bottom: 1px solid #ddd;*/
    }
</style>